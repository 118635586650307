import { useState } from "react";
import { NewNoteModal } from "../../interactions/components/new-note-modal";
import ButtonNeoGen from "../../layout/button-neogen";
import { Interaction } from "../../interactions/domain/interaction";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import Avatar from "react-avatar";
import { Company } from "../domain/company";
import { ClearERCUser } from "../../typings/api/clear-erc-user";

export const Notes = ({
    notes,
    onNoteCreated,
    companyOwnerId,
    user,
    company,
    users,
}: {
    notes: Interaction[];
    onNoteCreated: (note: Interaction) => void;
    companyOwnerId: string;
    user: User;
    company: Company;
    users: User[];
}) => {
    const [showNewNoteModal, setShowNewNoteModal] = useState(false);

    return (
        <div>
            {showNewNoteModal && (
                <NewNoteModal
                    forUserId={companyOwnerId}
                    onClose={() => setShowNewNoteModal(false)}
                    company={company}
                    onNoteCreated={(note) => {
                        onNoteCreated(note);
                        setShowNewNoteModal(false);
                    }}
                    users={users}
                />
            )}
            {notes.length === 0 && (
                <div className="flex justify-center items-center h-[70vh]">
                    <div className="text-center">
                        <div className="text-xl mb-4">No notes here yet...</div>
                        <ButtonNeoGen type="primary" onClick={() => setShowNewNoteModal(true)}>
                            Add a new note
                        </ButtonNeoGen>
                    </div>
                </div>
            )}
            {notes.length > 0 && (
                <>
                    <div className="flex flex-row justify-end mr-4">
                        <ButtonNeoGen type="primary" onClick={() => setShowNewNoteModal(true)}>
                            New note
                        </ButtonNeoGen>
                    </div>
                    <div className="flow-root">
                        <ul role="list" className="-mb-8">
                            {notes.map((note) => (
                                <li key={note.id}>
                                    <div className="relative pb-8">
                                        <div className="relative flex items-start space-x-3">
                                            <div className="relative pl-4 pt-2 pr-1">
                                                <Avatar
                                                    name={[
                                                        note.interactionFromUser?.firstName,
                                                        note.interactionFromUser?.lastName,
                                                    ].join(" ")}
                                                    size="44"
                                                    email={note.interactionFromUser?.email}
                                                    round={true}
                                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-slate-200 dark:ring-gray-700"
                                                />
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div>
                                                    <div className="inline-block">
                                                        {note.interactionFromUser?.firstName}{" "}
                                                        {note.interactionFromUser?.lastName}
                                                    </div>
                                                </div>
                                                <p className="text-xs text-gray-400">
                                                    {note.dateCreated && timeAgo(note.dateCreated).toString()}{" "}
                                                </p>
                                                <div className="flex">
                                                    <p className="text-sm text-gray-400">
                                                        Assigned to:{" "}
                                                        {[
                                                            note.interactionToUser?.firstName,
                                                            note.interactionToUser?.lastName,
                                                        ].join(" ")}
                                                    </p>
                                                </div>
                                                <div className="flex flex-row gap-1">
                                                    {note.isInternal && (
                                                        <span className="bg-gray-300 rounded-md p-1 text-sm w-auto">
                                                            Internal note
                                                        </span>
                                                    )}
                                                    {note.followupDatetime && (
                                                        <span className="bg-orange-300 rounded-md p-1 text-sm">
                                                            Needs follow up:{" "}
                                                            <b>{note.followupDatetime.toISOString()}</b>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="text-sm text-gray-700">
                                                    <p>{note.activity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};
